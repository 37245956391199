<script lang="ts" setup>
import { defineAsyncComponent, onMounted, ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';

import { balanceService } from '@exchange/libs/balances/service/src';
import { modalVariant } from '@exchange/libs/modals/src';
import { useInitTransaction } from '@exchange/libs/transactions/funds/service/src';
import fundsService from '@exchange/libs/transactions/funds/service/src/lib/funds.service';
import { AccountIsNotMain, CoinSelect, FetchBitpandaUser, UserBlocked, UserNotVerified, TransactionModal } from '@exchange/libs/transactions/shared/src';
import { launchdarkly } from '@exchange/libs/utils/launchdarkly/src';
import { logger } from '@exchange/libs/utils/simple-logger/src';

const DepositCrypto = defineAsyncComponent(() => import(/* webpackChunkName: 'chunk-deposit-crypto' */ './DepositCrypto.vue'));
const DepositFiat = defineAsyncComponent(() => import(/* webpackChunkName: 'chunk-deposit-fiat' */ './DepositFiat.vue'));
const DepositFiatCard = defineAsyncComponent(() => import(/* webpackChunkName: 'chunk-deposit-fiat-card' */ './DepositFiatCard.vue'));

const DepositMethod = defineAsyncComponent(() => import(/* webpackChunkName: 'chunk-deposit-method' */ './DepositMethod.vue'));

const router = useRouter();
const route = useRoute();

const { list: balances } = balanceService;

const cryptoDepositsEnabled = ref(false);
const fiatDepositsEnabled = ref(false);

const isCardPaymentEnabled = launchdarkly.flags['is-card-payment-enabled'].value;

const variant = modalVariant.dark;

const {
  currenciesToShow,
  defaultFiatCurrency,
  filterCurrencyList,
  getCurrencyFromRoute,
  isBlocked,
  isVerified,
  isMainAccount,
  isRetailWithMissingAml,
  resetSelectedCurrency,
  selectedCurrency,
  selectedCurrencyIsCrypto,
  verification,
  search,
  selectedCurrencyType,
} = useInitTransaction();

const selectedDepositMethod = ref('');
selectedCurrencyType.value = null;

const setDefaultsForCurrency = () => {
  if (selectedCurrency.value) {
    selectedDepositMethod.value = selectedCurrency.value.isFiat ? 'card' : 'crypto';
    selectedCurrencyType.value = selectedCurrency.value.isFiat ? 'fiat' : 'crypto';
  }
};

// If the currency is set in the route using query, set the defaults for the deposit method and currency type
(async () => {
  await getCurrencyFromRoute(route, router);
  setDefaultsForCurrency();
})();

const handleMethodSelected = (method: string) => {
  selectedDepositMethod.value = method;
  if (selectedDepositMethod.value === 'crypto') {
    selectedCurrencyType.value = 'crypto';
  } else {
    selectedCurrencyType.value = 'fiat';
  }
};

const handleChangeMethod = () => {
  selectedDepositMethod.value = '';
  selectedCurrencyType.value = null;
  search.value = '';
};

onMounted(async () => {
  try {
    const accountHolderDetails = await fundsService.accountHolder.get();

    cryptoDepositsEnabled.value = accountHolderDetails?.enabled_crypto_deposits ?? true;
    fiatDepositsEnabled.value = accountHolderDetails?.enabled_fiat_deposits ?? true;

    // Only set default values if no currency was selected from the route
    if (!selectedCurrency.value) {
      selectedDepositMethod.value = isCardPaymentEnabled && fiatDepositsEnabled.value ? 'card' : '';
      selectedCurrencyType.value = isCardPaymentEnabled && fiatDepositsEnabled.value ? 'fiat' : '';
    }
  } catch (e) {
    logger.error('Unable to get account holder details', e);
  }
});
</script>

<template>
  <transaction-modal
    class="deposit-modal"
    name="deposit"
    :variant="variant"
    :title="$t('fundamentals.addFunds')"
  >
    <template #content="{ clear, searchClass, coinSelectClass, contentClass }">
      <fetch-bitpanda-user :variant="variant">
        <div v-if="!selectedDepositMethod">
          <deposit-method
            :crypto-deposits-enabled="cryptoDepositsEnabled"
            :fiat-deposits-enabled="fiatDepositsEnabled"
            @method-selected="handleMethodSelected"
          />
        </div>
        <div v-if="fiatDepositsEnabled && selectedDepositMethod === 'card'">
          <component
            :is="DepositFiatCard"
            v-if="!isBlocked && isVerified && !isRetailWithMissingAml"
            :variant="variant"
            :currency-options="currenciesToShow"
            :deposit-method="selectedDepositMethod"
            :class="contentClass"
            @close="clear"
            @change-method="handleChangeMethod"
          />
        </div>
        <div v-else-if="selectedDepositMethod && selectedDepositMethod !== 'card'">
          <div
            class="deposit-modal__search"
            :class="searchClass"
          >
            <ot-search
              v-model:value="search"
              :variant="variant"
              :full-width="true"
              :on-search-cb="filterCurrencyList"
              testid="currency-search"
              @input-click="resetSelectedCurrency"
            />
          </div>
          <account-is-not-main
            v-if="!isMainAccount"
            operation="deposit"
            :variant="variant"
            :class="contentClass"
            @close="clear"
          />
          <coin-select
            v-else
            v-model:selectedCurrency="selectedCurrency"
            type="deposit"
            :variant="variant"
            :class="coinSelectClass"
            :currencies="currenciesToShow"
            :default-fiat-currency="defaultFiatCurrency"
            :balances="balances"
          />
        </div>
        <component
          :is="selectedCurrencyIsCrypto ? DepositCrypto : DepositFiat"
          v-if="selectedDepositMethod && selectedDepositMethod !== 'card' && selectedCurrency && !isBlocked && isVerified && !isRetailWithMissingAml"
          :key="selectedCurrency.id"
          :variant="variant"
          :currency="selectedCurrency"
          :deposit-method="selectedDepositMethod"
          :class="contentClass"
          @close="clear"
        />
        <user-blocked
          v-else-if="selectedCurrency && isBlocked"
          :class="contentClass"
          :variant="variant"
          @close="clear"
        />
        <user-not-verified
          v-else-if="selectedCurrency && (!isVerified || isRetailWithMissingAml)"
          :variant="variant"
          :verification="verification"
          :currency-symbol="selectedCurrency.id"
          :class="contentClass"
          text-key="modules.transactions.verification.commonText"
          link-text-key="modules.transactions.verification.button"
        />
      </fetch-bitpanda-user>
    </template>
  </transaction-modal>
</template>
