import { TimeInForceType } from '@exchange/libs/order/shared-model/src/lib/order-essentials';
import { CONSTANTS } from '@exchange/libs/utils/constants/src';

export interface OrderbookSettings {
  spreadInBasePoints?: boolean;
}

export interface RateLimitWarnings {
  showCloseToHitting: boolean;
  showHit: boolean;
}

export interface OrderConfirmations {
  market?: boolean;
  limit?: boolean;
  stop?: boolean;
}
export interface OrderAdvancedSettings {
  enabled: boolean;
  timeInForce?: TimeInForceType;
  postOnly?: boolean;
  cancelAfter?: number;
}

export interface OrderNotifications {
  all?: boolean;
  created?: boolean;
  filled?: boolean;
  triggered?: boolean;
  canceled?: boolean;
  rejected?: boolean;
  failed?: boolean;
}

export interface SeenPartnersAt {
  // partnerKey: whenClosed;
  [partnerKey: string]: number;
}

export interface MyOrdersSettings {
  showOrdersFromAllMarkets?: boolean;
  allowAnimations?: boolean;
  hideCancelledOrders?: boolean;
  hideFilledOrders?: boolean;
  showTimestamp?: boolean;
}

export interface FavoriteMarkets {
  enabled?: boolean;
  list?: Array<string>;
}

export interface MarketList {
  spotTab?: string;
}

export interface MarketPromotion {
  timestamp: number | undefined;
}

export interface BESettings {
  currency?: string;
  favoriteMarkets?: FavoriteMarkets;
  language?: string;
  marketList?: MarketList;
  marketOrdersSlippage?: number;
  marketPromotion?: MarketPromotion;
  myOrdersSettings?: MyOrdersSettings;
  orderAdvanced?: OrderAdvancedSettings;
  orderbookSettings?: OrderbookSettings;
  orderConfirmations?: OrderConfirmations;
  orderNotifications?: OrderNotifications;
  rateLimitWarnings?: RateLimitWarnings;
  riskAgreement?: string;
  seenPartnersAt?: SeenPartnersAt;
  termAndConditions042024?: string;
}

export const defaultBESettings = {
  language: undefined,
  currency: CONSTANTS.DEFAULT_CURRENCIES.fiat,
  riskAgreement: undefined,
  orderAdvanced: {
    enabled: false,
    timeInForce: TimeInForceType.GOOD_TILL_CANCELLED,
    postOnly: false,
    cancelAfter: 60,
  },
  orderbookSettings: {
    spreadInBasePoints: false,
  },
  orderConfirmations: {
    market: true,
    limit: true,
    stop: true,
  },
  orderNotifications: {
    all: true,
    created: true,
    filled: true,
    triggered: true,
    canceled: true,
    rejected: true,
    failed: true,
  },
  favoriteMarkets: {
    enabled: false,
    list: [],
  },
  marketList: {
    spotTab: undefined,
  },
  marketOrdersSlippage: 25,
  seenPartnersAt: {},
  myOrdersSettings: {
    showOrdersFromAllMarkets: true,
    allowAnimations: true,
    hideCancelledOrders: false,
    hideFilledOrders: false,
    showTimestamp: true,
  },
  marketPromotion: {
    timestamp: undefined,
  },
  rateLimitWarnings: {
    showCloseToHitting: true,
    showHit: true,
  },
};

export default class Settings {
  public language?: string;

  public currency?: string;

  public riskAgreement?: string;

  public termAndConditions?: string;

  public orderConfirmations: OrderConfirmations;

  public orderNotifications: OrderNotifications;

  public orderAdvanced: OrderAdvancedSettings;

  public orderbookSettings: OrderbookSettings;

  public favoriteMarkets: FavoriteMarkets;

  public marketList: MarketList;

  public seenPartnersAt: SeenPartnersAt;

  public myOrdersSettings: MyOrdersSettings;

  public marketOrdersSlippage: number;

  public marketPromotion: MarketPromotion;

  public rateLimitWarnings: RateLimitWarnings;

  constructor(fields: BESettings) {
    this.language = fields.language ?? defaultBESettings.language;
    this.currency = fields.currency ?? defaultBESettings.currency;
    this.riskAgreement = fields.riskAgreement;
    this.termAndConditions = fields.termAndConditions042024;

    this.orderAdvanced = {
      ...defaultBESettings.orderAdvanced,
      ...(fields?.orderAdvanced && fields.orderAdvanced),
    };
    this.orderConfirmations = {
      ...defaultBESettings.orderConfirmations,
      ...(fields?.orderConfirmations && fields.orderConfirmations),
    };
    this.orderNotifications = {
      ...defaultBESettings.orderNotifications,
      ...(fields.orderNotifications && fields.orderNotifications),
    };
    this.orderbookSettings = {
      ...defaultBESettings.orderbookSettings,
      ...(fields.orderbookSettings && fields.orderbookSettings),
    };
    this.favoriteMarkets = {
      ...defaultBESettings.favoriteMarkets,
      ...(fields.favoriteMarkets && fields.favoriteMarkets),
    };
    this.marketList = {
      ...defaultBESettings.marketList,
      ...(fields.marketList && fields.marketList),
    };
    this.seenPartnersAt = fields.seenPartnersAt ?? defaultBESettings.seenPartnersAt;
    this.myOrdersSettings = {
      ...defaultBESettings.myOrdersSettings,
      ...(fields.myOrdersSettings && fields.myOrdersSettings),
    };
    this.marketPromotion = fields.marketPromotion ?? defaultBESettings.marketPromotion;
    this.rateLimitWarnings = fields.rateLimitWarnings ?? defaultBESettings.rateLimitWarnings;
    this.marketOrdersSlippage = fields.marketOrdersSlippage ?? defaultBESettings.marketOrdersSlippage;
  }
}
