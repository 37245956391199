import { omit } from 'lodash/fp';
import { computed, ref, watch } from 'vue';
import { type Router, type RouteLocationNormalizedLoaded } from 'vue-router';

import filterBy from '@exchange/helpers/filter-by';
import fulfillWithTimeLimit from '@exchange/helpers/fulfill-with-time-limit';
import { filter } from '@exchange/helpers/lodash-fp-no-cap';
import getFromRouterQuery from '@exchange/helpers/query-param-to-string';
import { accountService } from '@exchange/libs/account/service/src';
import { subaccountsService } from '@exchange/libs/trading-accounts/service/src';
import { currencyService, CurrencyModel } from '@exchange/libs/utils/currency/src';
import { launchdarkly } from '@exchange/libs/utils/launchdarkly/src';

const useInitTransaction = ({ withAutomaticSwitchToMain } = { withAutomaticSwitchToMain: true }) => {
  const { accountUser, accountIdHolder, isMainAccount, userIsBlocked, retailAccountVerificationInfo } = accountService;
  const isFullyVerified = computed(() => Boolean(accountUser.value?.isFullyVerified));
  const isLightlyVerified = computed(() => Boolean(accountUser.value?.isLightlyVerified));
  const isVerified = computed(() => isFullyVerified.value || isLightlyVerified.value);
  const isRetail = computed(() => accountService.accountUser.value?.isRetail);
  const isRetailWithMissingAml = computed(() => isRetail.value && !retailAccountVerificationInfo.value?.aml?.date);

  const { currencies, defaultFiatCurrency, waitForCurrencies } = currencyService;
  const currencyOptions = computed(() => (currencies.value ? Object.values(currencies.value) : []));
  const currencyOptionsInternal = ref<Array<CurrencyModel> | undefined>(undefined);
  const selectedCurrency = ref<CurrencyModel>();
  const selectedCurrencyType = ref<string | null>(null);

  const currenciesToShow = computed(() => {
    const options = currencyOptionsInternal.value || currencyOptions.value;

    if (selectedCurrencyType.value === 'fiat') {
      return options.filter((currency) => currency.isFiat);
    }
    if (selectedCurrencyType.value === 'crypto') {
      return options.filter((currency) => !currency.isFiat);
    }
    return options;
  });

  const search = ref<string>();

  const { verification } = launchdarkly.flags;

  const selectedCurrencyIsCrypto = computed(() => {
    if (!selectedCurrency.value) {
      return undefined;
    }

    return !selectedCurrency.value.isFiat;
  });

  const filterByIdAndName = filterBy(['name', 'id']);
  const filterCurrencyList = (s: string) => {
    currencyOptionsInternal.value = filter(filterByIdAndName(s), currencyOptions.value);
  };
  const resetSelectedCurrency = () => {
    selectedCurrency.value = undefined;
  };

  const waitForCurrenciesWithTimeLimitAndSetSelectedOne = async (currency?: string) => {
    if (!currency) {
      return;
    }

    await fulfillWithTimeLimit<Dictionary<CurrencyModel>>(waitForCurrencies(), 5_000, {});

    if (currencies.value) {
      selectedCurrency.value = currencies.value[currency];
    }
  };

  const getAndRemoveParamsFromRouteQuery = async (route: RouteLocationNormalizedLoaded, router: Router, paramsName: Array<string>) => {
    const params = paramsName.map((item: string) => getFromRouterQuery(item, route));
    const restQ = omit(paramsName, route.query);

    await router
      .replace({
        ...route,
        query: restQ,
      })
      .catch(() => {});

    return params;
  };

  const getCurrencyFromRoute = async (route: RouteLocationNormalizedLoaded, router: Router) => {
    const [currency] = await getAndRemoveParamsFromRouteQuery(route, router, ['currency']);

    await waitForCurrenciesWithTimeLimitAndSetSelectedOne(currency);
  };

  watch(
    isMainAccount,
    (value) => {
      if (!value && withAutomaticSwitchToMain && accountIdHolder.value) {
        subaccountsService.switchTo(accountIdHolder.value);
      }
    },
    { immediate: true },
  );

  return {
    search,
    currenciesToShow,
    currencyOptions,
    defaultFiatCurrency,
    filterCurrencyList,
    getAndRemoveParamsFromRouteQuery,
    getCurrencyFromRoute,
    isBlocked: userIsBlocked,
    isVerified,
    isMainAccount,
    isRetailWithMissingAml,
    resetSelectedCurrency,
    selectedCurrency,
    selectedCurrencyIsCrypto,
    selectedCurrencyType,
    verification,
    waitForCurrenciesWithTimeLimitAndSetSelectedOne,
  };
};

export default useInitTransaction;
